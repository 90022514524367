import logo from './logo.svg';
import './App.css';
import React from 'react'
import ReactDOM from 'react-dom'
import LandingPage from './components/LandingPage';
import CarouselExport from './components/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
     <CarouselExport> </CarouselExport>
    </div>
  );
}

export default App;
