import React from "react";


function LandingPage() {
    return (
        <h>HELLO</h>
    )
}


export default LandingPage;