import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Zoom, Fade } from 'react-reveal/';
import GoogleMapReact from 'google-map-react';

function hello() {
    for (var i = 0; i < 20; i++) {
        console.log("hello")
    }
}

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function CarouselExport() {
    const defaultProps = {
        center: {
            lat: 36.16293310100453,
            lng: -86.51206454293542
        },
        zoom: 11
    };

    return (
        <div>
            <div className="nav-bar">
                <img className="navLogo" src="assets/B2_logo.png"></img>
            </div>

            <div>
                <a href="https://b2-nails.square.site">
                <button className="bookNowButton">Book Now</button>
                </a>
            </div>

            <div style={{ marginTop: 50, marginLeft: 50, marginRight: 50 }}>
                <hr />
            </div>

            <Container>
                <Row xs={1} md={1} lg={2}>
                    <Fade left>
                        <Col className="col padding"><img src="assets/profile_pic.JPG" /></Col>
                    </Fade>
                    <Fade right>
                        <Col className="col padding">

                            <hr />
                            <p className="col-text-1">Starting out as a new hobby, it evolved into a passion that has spanned over 7 years. Hi, my name is Christin, a nail technician with 7 years of experience. I moved to Mount Juliet about 7 years ago and have fallen in love with the place ever since. Recognizing this as my new home, I devoted myself to perfecting the art of nail design. Don't believe me? Below are some examples of my best nail works.
                            </p>
                            <h3 className="col-text-bio">
                                - Christin Mam
                            </h3>
                        </Col>
                    </Fade>
                </Row>
            </Container>

            <div style={{ marginTop: 50, marginLeft: 50, marginRight: 50 }}>
                <hr />
            </div>

            <div>
                <h className="col-text">
                    Work Portfolio
                </h>
            </div>

            <Container>
                <Zoom>
                    <Row xs={1} sm={1} md={1} lg={3} xxl={3} xl={3}>
                        <Col className="col-two padding"><img className="img1" src="assets/Image1.jpg" /></Col>
                        <Col className="col-two padding"><img className="img1" src="assets/Image2.jpg" /></Col>
                        <Col className="col-two padding"><img className="img1" src="assets/Image7.jpg" /></Col>
                    </Row>
                    <Row xs={1} sm={1} md={1} lg={3} xxl={3} xl={3}>
                        <Col className="col-two padding"><img className="img1" src="assets/Image4.jpg" /></Col>
                        <Col className="col-two padding"><img className="img1" src="assets/Image5.jpg" /></Col>
                        <Col className="col-two padding"><img className="img1" src="assets/Image6.jpg" /></Col>
                    </Row>
                </Zoom>
            </Container>

            <div>
                <div className="footer">
                    <hr style={{ marginLeft: 50, marginRight: 50 }} />
                    <h className="footerText">Contact Me At:</h>
                    <div>
                        <a className="contactText" href="mailto: b2nailsmj@gmail.com">b2nailsmj@gmail.com</a>
                    </div>
                    <div>
                        <a className="contactText" href="tel:408-439-2413">(408) 439-2413</a>
                    </div>

                    <div>
                        <a href="https://b2-nails.square.site">
                        <button className="bookNowButtonBottom">Book Now</button>
                        </a>
                       
                    </div>

                    <div className="socialMediaSpacer">
                        <a href="http://www.instagram.com/b2nailsmj"> <img className="socialMediaImg" src="assets/instagram-color-icon.png"></img></a>
                    </div>
                    <div className="socialMediaSpacer">
                        <a href="https://www.facebook.com/profile.php?id=61550681407527"> <img className="socialMediaImg" src="assets/facebook-round-color-icon.png"></img></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarouselExport;
